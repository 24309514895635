#root {
  position: relative;
  min-height: 100vh;
}
html,
body {
  min-width: fit-content;
}
body {
  letter-spacing: 0.073em;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
button {
  letter-spacing: 0.09em;
  border-radius: 17px !important;
}
p,
ol,
ul,
li {
  font-size: 19px !important;
  letter-spacing: 0.073em;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
footer p {
  font-size: 13px !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  letter-spacing: 0.073em;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  color: #1c1c2b;
}
footer p {
  color: #ffffff;
}
input {
  letter-spacing: 0.13em;
}
/* Simple Search PlaceHolder/Label Styles */
/* .form-outline .form-control~.form-label {
  font-size: 15px !important;
  line-height: 50px !important;
} */
/* body {
  background-image: url('../Components/Home/Assets/Background/alium-maintenance.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
} */
/* .maintenance_background {
  background-image: url('../Components/Home/Assets/Background/alium-maintenance.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
} */
.android-notification {
  padding: 15px
}
.margin-zero {
  margin: 0 !important;
}
.box_shadow_dark {
  box-shadow: 0 6px 12px -3px #1c1c2b !important;
}
.box_shadow {
  box-shadow: 0 4px 10px -2px #1c1c2b !important;
}
.box_shadow_light {
  box-shadow: 0 3px 9px -3px #1c1c2b !important;
}
.no_box_shadow {
  box-shadow: none !important;
}
.border_radius {
  border-radius: 17px !important;
}
.border_radius_curvier {
  border-radius: 27px;
}
.card-header {
  font-weight: bold;
}
.card-title {
  font-size: 50px !important;
  margin: 0 !important;
}
.custom-link a {
  color: #ffffff !important;
}
.custom-button {
  background-color: #1c1c2b !important;
  box-shadow: 0 4px 9px -6px #1c1c2b !important;
  padding: 15px 40px !important;
  font-size: 17px !important;
  border-radius: 17px;
}
.custom-button-simple-search {
  background-color: #1c1c2b !important;
  box-shadow: 0 4px 9px -3px #1c1c2b !important;
  padding: 10px 40px !important;
  font-size: 17px !important;
  border-radius: 16px;
}
.custom-button_advanced_search {
  background-color: #1c1c2b !important;
  box-shadow: 0 4px 9px -3px #1c1c2b !important;
  padding: 15px 40px !important;
  font-size: 17px !important;
  border-radius: 17px !important;
}
.cta-button {
  background-color: #b20000 !important;
  box-shadow: none !important;
  border-radius: 17px !important;
}
.get-advice-button {
  background-color: #1c1c2d !important;
  box-shadow: 0 4px 9px -4px #1c1c2b !important;
  border-radius: 17px !important;
}
.how_to_use_btn {
  color: #b20000 !important;
  background-color: #ffffff !important;
  box-shadow: 0 4px 9px -1px #b20000 !important;
  padding: 15px 40px !important;
  font-size: 17px !important;
  border-radius: 17px !important;
}
.bold_text {
  font-weight: 900;
}
.margin_right {
  margin-right: 10px !important;
}
/* Buy Credits Modal Styles Start*/
.navbar_button {
  background-color: #1c1c2b !important;
}
.modal-dialog {
  max-width: fit-content !important;
}
.buy_credits_title {
  color: #b20000 !important;
  font-weight: 900 !important;
}
.credits_title_buy_credits {
  font-size: 21px !important;
}
.buy_credits_button {
  background-color: #b20000 !important;
  transition: ease-in-out 1s;
  font-size: 16px !important;
  padding: 7px 15px !important;
  color: #ffffff;
  box-shadow: none !important;
  border-radius: 17px !important;
}
.buy_credits_button:hover {
  background-color: #1c1c2d !important;
  transition: ease-in-out 1s;
  font-size: 16px !important;
  padding: 7px 15px !important;
  color: #ffffff;
  box-shadow: 0 0 5px #1c1c2d !important;
  border-radius: 17px !important;
}
.close_button {
  background-color: #1c1c2b !important;
  color: #ffffff !important;
  box-shadow: none !important;
  border-radius: 17px !important;
}
/* .css-1vooibu-MuiSvgIcon-root {
  font-size: 30px !important;
} */
.close_button:hover {
  background-color: #c6b49f !important;
  color: #1c1c2b !important;
  box-shadow: none !important;
  border-radius: 17px !important;
}
.carousel-control-next-icon {
  color: #ffffff !important;
  text-shadow: 0 0 5px #000000 !important;
}
.carousel-control-prev-icon {
  color: #ffffff !important;
  text-shadow: 0 0 5px #000000 !important;
}
.total_notification {
  border-radius: 17px !important;
  background-color: #ffffff !important;
  padding: 2px 10px;
  color: #1c1c2d !important;
}
.total_notification:hover {
  border-radius: 17px;
  background-color: #1c1c2d !important;
  padding: 2px 10px;
  color: #ffffff !important;
}
.active .total_notification {
  border-radius: 17px;
  background-color: #1c1c2d !important;
  padding: 2px 10px;
  color: #ffffff !important;
}
.dark_bg {
  background-color: #1c1c2d !important;
}
.flex_on_desktop {
  display: inline-flex !important;
  justify-content: flex-start !important;
  min-width: 100% !important;
}
.tablet_credits_cards_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.side_margin {
  margin: 0 15px;
}
.btn-success {
  box-shadow: none !important;
}
/* HOW TO USE MODAL STYLES */
.new_launch_padding {
  padding: 25px;
}
/* Buy Credits Modal Styles End*/
.view_pdf_button {
  background-color: #b20000 !important;
  border-radius: 17px !important;
  box-shadow: none !important;
}
.vertical_border {
  width: auto;
  height: auto; /* Adjust the height as needed */
  border-left: 1px solid rgba(28, 28, 43, 0.53);
}
.vertical_border_light {
  width: auto;
  height: auto; /* Adjust the height as needed */
  border-left: 1px solid rgba(28, 28, 43, 0.53);
}
.vertical_border_tabs {
  width: auto;
  height: auto; /* Adjust the height as needed */
  border-left: 1px solid #1c1c2b;
  margin: 0;
}
.vertical_border_loader {
  width: auto;
  height: auto; /* Adjust the height as needed */
  border-left: 1px solid #ffffff;
  margin: 0;
}
.horizontal_border {
  width: auto;
  height: auto;
  border: 1px solid rgba(28, 28, 43, 0.23);
  margin: 50px 0 35px;
}
.horizontal_border_simple_search {
  width: auto;
  height: auto;
  border: 1px solid rgba(28, 28, 43, 0.23);
  margin: 25px 0 40px;
}
.horizontal_border_receipts {
  width: auto;
  height: auto;
  border: 1px solid rgba(28, 28, 43, 0.23);
  margin: 15px 0;
}
.horizontal_border_active_expanded {
  width: 100%;
  height: auto;
  border: 1px solid rgba(28, 28, 43, 0.23);
  margin: 15px 0;
}
.horizontal_border_active_expanded_white {
  width: 100%;
  height: auto;
  border: 1px solid #ffffff;
  margin: 15px 0;
}
.horizontal_border_active_expanded_tabs {
  width: 100%;
  height: auto;
  border: 1px solid #1c1c2d;
  margin: 15px 0;
}
.horizontal_border_active_expanded_tabs_white {
  width: 100%;
  height: auto;
  border: 1px solid #ffffff;
  margin: 15px 0;
}
.repurchase_button {
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;
  background-color: #b20000 !important;
}
.repurchase_button:hover {
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;
  background-color: #1c1c2d !important;
  color: #ffffff;
  transition: ease-in-out 1s;
  text-shadow: 0 0 1px #ffffff;
  box-shadow: none !important;
}
.login_button {
  background-color: #b20000 !important;
  box-shadow: 0 4px 9px -6px #b20000 !important;
  border-radius: 17px !important;
}
.logout_button {
  background-color: #1c1c2b !important;
}
.buy_credit_btn {
  background-color: #b20000 !important;
  box-shadow: none !important;
  border-radius: 17px !important;
}
.buy_credit_btn:hover {
  background-color: #1c1c2d !important;
  transition: ease-in-out 1s;
  box-shadow: none !important;
  border-radius: 17px !important;
}

/* Desktop Update Toast Styles */
.desktop_toast {
  z-index: 9999 !important;
  margin-top: 83px !important;
}
.total_notification_light {
  border-radius: 17px !important;
  background-color: #1c1c2d !important;
  padding: 2px 10px;
  color: #ffffff !important;
}
.btn-light {
  background-color: unset !important;
}
.zero_margin {
  margin: 0 !important;
}
.zero_padding {
  padding: 0 !important;
}

/* DASHBOARD STYLES START */
.tab_data_section_spacing {
  min-height: 500px;
  margin: 0 50px;
}
.side_spacing {
  margin: 0 30px;
}
/* User Details Section Styles Start */
.user_details_initials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.user_details_initials h1 {
  color: #1c1c2b;
  font-size: 57px;
  margin: 0;
  padding: 0;
}
.user_details_name_and_email {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 25px;
}
.user_details_bordered_first_name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: underline;
  width: auto;
}
.user_details_bordered_first_name h1 {
  text-transform: uppercase !important;
  color: #1c1c2b;
  font-size: 24px;
  font-weight: 600;
  margin: 0 !important;
  padding: 0 !important;
}
.user_credits {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 25px;
}
.credits_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: 17px;
  box-shadow: 0 4px 10px -3px #1c1c2b;
}
.credits_title h3 {
  text-transform: uppercase !important;
  color: #b20000;
  font-size: 13px;
  font-weight: 900;
  margin: 0;
  padding: 0;
}
.last_purchase_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  border-radius: 17px;
  box-shadow: 0 4px 10px -3px #1c1c2b;
}
.last_purchase_title h3 {
  text-transform: uppercase !important;
  color: #b20000 !important;
  font-size: 13px !important;
  font-weight: 900;
  margin: 0;
  padding: 0;
}
.last_purchase_title .credits_icon {
  font-size: 21px !important;
  color: #b20000 !important;
}
.purchased_credits_text {
  padding: 5px 15px !important;
}
.credits_figures h3 {
  color: #1c1c2b;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.credits_icon {
  padding: 5px 15px;
  border-right: 1px solid rgba(28, 28, 43, 0.23);
  color: #b20000;
  font-weight: 900;
  font-size: 21px;
}
/* User Deatils Section Styles End */
.nairobi_badge {
  font-size: 15px !important;
  font-weight: 300 !important;
  border: 1px solid rgba(28, 28, 43, 0.23);
  border-radius: 17px;
  color: #ffffff;
  padding: 1px 15px;
  background-color: #1c1c2b;
  box-shadow: 0 4px 9px -3px #1c1c2b !important;
}
.nairobi_badge_advanced_search {
  font-size: 13px !important;
  border-radius: 17px;
  color: #ffffff;
  padding: 5px 17px;
  background-color: #1c1c2b;
  /* box-shadow: 0 4px 9px -4px #1c1c2b !important; */
  box-shadow: 0 4px 9px -7px #ffffff !important;
}
.nav-tabs {
  background-color: #1c1c2b;
  border-radius: 17px !important;
  padding: 30px 15px !important;
}
.nav-item {
  margin: 0 15px;
}
.nav-link {
  color: #ffffff !important;
  border: 1px solid #ffffff;
  border-radius: 17px !important;
}
.nav-link:hover {
  color: #1c1c2b !important;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show {
  color: #1c1c2b !important;
  border-color: rgba(28, 28, 43, 0.23) !important;
}
.nav-tabs .nav-link {
  font-size: 21px !important;
}

/* UPDATE NOTIFICATION */
.toast {
  box-shadow: 0 0 17px #1c1c2d !important;
  width: 500px !important;
}
.toast-body {
  text-align: left !important;
  border-radius: 17px !important;
}
.toast-header {
  text-align: left !important;
}
.toast-body p,
li {
  font-size: 16px !important;
}
/* Search Results Number Icon Styles */
.number_results_icon {
  display: flex;
  flex-direction: column;
  border-radius: 17px;
  align-self: center;
  margin: 0px;
  color: #1c1c2d !important;
}
.search_results_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.inner_search_results_wrapper {
  box-shadow: 0 4px 10px -1px #1c1c2b !important;
  border-radius: 17px;
  padding: 0;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.inner_search_results_wrapper_row {
  box-shadow: 0 4px 10px -1px #1c1c2b !important;
  border-radius: 17px;
  padding: 0;
  display: flex;
  overflow-y: auto;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}
/* Advanced Search Background Hero Image */
.advanced_page_hero_background_image {
  background-image: url("../Components/Pages/AdvancedSearch/Assets/Images/as_bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(28, 28, 43, 0.93);
  z-index: 2;
}
/* Advanced Search Hero H1 */
.hero_h1 h1 {
  color: #ffffff;
  font-weight: 900;
  text-shadow: 1px 3px 5px #1c1c2b;
  font-size: 50px;
}
.hero_wrapper {
  margin: 40px 50px 0;
}
/* Advanced Search LR No Input Field */
.advanced_search_lr_no_input_field {
  width: 100%;
}
.custom_input_label {
  text-align: left;
  width: auto;
  margin-right: 10px; /* Adjust margin as needed */
}

/* Caret Square Icons Styles Start */
.toggle-icon {
  transition: transform 0.3s ease-in-out; /* Set the transition duration */
  transform: rotate(0deg); /* Initial rotation */
}

.toggle-icon.expanded {
  transition: transform 0.3s ease-in-out;
  transform: rotate(180deg); /* Rotate by 180 degrees when expanded */
}
/* Caret Square Icons Styles End */

/* Active Tab Expanded Styles Start */
.ActiveExpanded {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.ActiveExpanded.show {
  max-height: 1000px; /* Adjust the value as needed */
}
/* Active Tab Expanded Styles End */

/* DASHBOARD STYLES END */
.drawer_custom_width {
  width: 500px !important;
}

/* MOBILE ONLY STYLES START */
@media screen and (max-width: 560px) {
  /* General Styles Start */
  html {
    min-width: 100%;
    scroll-behavior: smooth;
    overflow-x: hidden;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }
  html::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  .hide-mobile {
    display: none !important;
  }
  .container,
  .container-fluid,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm {
    padding: 0 !important;
  }
  .max_width {
    width: max-content;
  }
  .black_mobile {
    color: #000000 !important;
  }
  .black_mobile a {
    color: #000000 !important;
  }
  .bold_text {
    font-weight: 500;
  }
  .center_mobile {
    text-align: center !important;
  }
  .side_margin {
    margin: 0;
  }
  .tablet_credits_cards_wrapper {
    flex-direction: column !important;
  }
  .purchased_credits_text {
    padding: 5px 15px !important;
    align-self: center !important;
  }
  .purchased_credits_text h3 {
    font-size: 16px !important;
    align-self: center !important;
    text-wrap: wrap !important;
  }
  .horizontal_border_active_expanded_white {
    width: 100%;
    height: auto;
    border: 1px solid #ffffff;
    margin: 0;
  }
  /* Horizontal Border Styling */
  .horizontal_border_mobile {
    width: 100% !important;
    border: 1px solid rgba(28, 28, 43, 0.23) !important;
    margin: 5px 0 !important;
  }
  .mobile_hero_horizontal_border {
    width: auto;
    height: auto;
    border: 1px solid rgba(28, 28, 43, 0.53);
    margin: 0;
  }
  .bottom_dashboard {
    display: flex;
    flex-direction: column;
  }
  .dashboard_icon {
    padding: 5px 15px;
    color: #b20000;
    font-weight: 900;
    font-size: 21px;
  }

  .vertical_border {
    width: auto;
    height: auto;
    border-left: 1px solid rgba(28, 28, 43, 0.53);
  }
  .vertical_border_light {
    width: auto;
    height: auto; /* Adjust the height as needed */
    border-left: 1px solid rgba(28, 28, 43, 0.33);
  }
  /* General Styles Start */

  /* AppBar Styles Start */
  .navbar-nav .nav-link {
    color: #000000 !important;
    font-weight: 700 !important;
  }
  .total_notification_light {
    border-radius: 17px !important;
    background-color: #1c1c2d !important;
    padding: 2px 10px;
    color: #ffffff !important;
  }
  .drawer_custom_width {
    width: 350px !important;
  }
  .how_to_use_btn {
    padding: 15px !important;
  }
  /* AppBar Styles End */

  /* Mobile Update Toast Styles Start */
  .mobile_toast {
    display: flex;
    justify-content: center;
    z-index: 900 !important;
    width: 100% !important;
    margin-top: 100px !important;
  }
  .toast {
    box-shadow: 0 0 17px #1c1c2d !important;
    width: 100% !important;
  }
  /* Mobile Update Toast Styles End */

  /* Advanced Search Styles Start */
  .tab_data_section_spacing {
    min-height: 500px;
    margin: 0 15px;
  }
  .side_spacing {
    margin: 0 15px;
  }
  .hero_wrapper {
    margin: 40px 15px 0;
  }
  .hero_h1 h1 {
    font-size: 27px !important;
    text-shadow: 1px 3px 5px #1c1c2b !important;
  }
  .center_mobile {
    justify-content: center;
  }
  .new_launch_padding {
    padding: 15px;
  }
  .dashboard_wrapper {
    margin: 25px 15px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 0 33px #1c1c2b;
    border-radius: 17px;
  }
  /* Tab Section Styles Start */
  .nav-item {
    margin: 10px 15px;
    width: 100% !important;
  }
  .nav-tabs .nav-link {
    line-height: 30px !important;
    padding: 20px !important;
  }
  .total_notification {
    border-radius: 17px !important;
    background-color: #ffffff !important;
    padding: 2px 10px;
    color: #1c1c2d !important;
    margin: 5px;
  }
  .user_details_name_and_email {
    padding: 0;
  }
  .last_purchase_title {
    display: flex;
    flex-direction: row;
    align-items: normal;
    box-shadow: 0 4px 10px -3px #1c1c2b;
    border-radius: 17px;
    width: 100%;
  }
  .last_purchase_title h3 {
    font-size: 16px !important;
  }
  .credits_title {
    display: flex;
    flex-direction: row;
    align-items: normal;
    width: 100%;
    box-shadow: 0 2px 10px -3px #1c1c2b !important;
    border-radius: 17px;
  }
  .credits_icon {
    border-right: none !important;
  }
  .credit_icon_wrapper {
    align-self: center;
  }
  /* Tab Section Styles End */

  /* Advanced Search Styles End */
}

/* @media screen and (max-width: 320px) {
  .credits_title {
    display: flex;
    flex-direction: row;
    align-items: normal;
    width: 100%;
    box-shadow: 0 4px 10px -3px #1c1c2b;
    border-radius: 17px;
  }
} */
/* MOBILE ONLY STYLS END */

/* TABLET STYLES START */
@media screen and (min-width: 561px) and (max-width: 868px) {
  /* Advanced Search Hero H1 */
  .hero_h1 h1 {
    color: #ffffff !important;
    font-weight: 900 !important;
    text-shadow: 1px 3px 5px #1c1c2b !important;
    font-size: 43px !important;
  }
  .tabs-only {
    display: block !important;
  }
  .hide-tabs {
    display: none !important;
  }
  .side_margin {
    margin: 0;
  }
  .dashboard_wrapper {
    margin: 25px 50px 0;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 0 33px #1c1c2b;
    border-radius: 17px;
  }
  .bottom_dashboard {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .user_details_initials h1 {
    color: #1c1c2b;
    font-size: 69px;
    margin: 0;
    padding: 0;
  }
  /* Hero section horizontal border */
  .user_details_name_and_email {
    padding: 0;
  }
  .tab_hero_horizontal_border {
    width: auto;
    height: auto;
    border: 1px solid rgba(28, 28, 43, 0.53);
    margin: 0;
  }
  /* Advanced Search Hero H1 */
  .hero_h1 h1 {
    color: #ffffff;
    font-weight: 900;
    text-shadow: 1px 3px 5px #1c1c2b !important;
    font-size: 50px;
  }
  /* Expired Tab Number Section */
  .number_results_icon {
    display: flex;
    flex-direction: column;
    border-radius: 17px;
    align-self: center;
    margin-right: 0;
    color: #1c1c2d !important;
  }
  /* Expired Search Tab Wrapper */
  .inner_search_results_wrapper {
    box-shadow: 0 4px 12px -1px #1c1c2b !important;
    border-radius: 17px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
  }
  /* Menu Tabs Styling */
  .menu_tab {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    padding: 20px 0 !important;
  }
  /* Credits Section Styles Start */
  .tablet_credits_cards_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around !important;
  }
  .tablet_wrap {
    flex-basis: 300px !important;
    margin: 15px 0;
  }
}
.drawer_custom_width {
  width: 350px !important;
}
/* TABLET STYLES END */

@media screen and (min-width: 869px) {
  .hide-desktop {
    display: none !important;
  }
  .drawer_custom_width {
    width: 350px !important;
  }
}
